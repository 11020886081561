import jQuery from "jquery";
import { WebAuth } from "auth0-js";

// export for others scripts to use
window.jQuery = jQuery;

(function($, window, document, undefined) {
  const initLoginForm = function(config) {
    const AUTH0_FLAG = "auth0";
    const LEARNING_SYSTEM_FLAG = "lms";

    const hideUsernameFields = () => {
      $(config.container)
        .find(".action-username")
        .addClass("hidden");
    };

    const showUsernameFields = () => {
      $(config.container)
        .find(".action-username")
        .removeClass("hidden");
    };

    const hidePasswordFields = () => {
      $(config.container)
        .find(".action-password")
        .addClass("hidden");
    };

    const showPasswordFields = () => {
      $(config.container)
        .find(".action-password")
        .removeClass("hidden");
    };

    const hideSpinner = () => {
      $(config.container)
        .find(".vue-simple-spinner")
        .addClass("hidden");
    };

    const showSpinner = () => {
      $(config.container)
        .find(".vue-simple-spinner")
        .removeClass("hidden");
    };

    const disableNextButton = () => {
      showSpinner();
      let $nextButton = $(config.container).find(".action-next");
      $nextButton.prop("disabled", "disabled");
      $nextButton
        .children("span")
        .first()
        .addClass("hidden");
    };

    const enableNextButton = () => {
      hideSpinner();
      let $nextButton = $(config.container).find(".action-next");
      $nextButton.prop("disabled", false);
      $nextButton
        .children("span")
        .first()
        .removeClass("hidden");
    };

    const showErrors = errors => {
      let errorHTML = "";

      if (errors.length > 1) {
        errorHTML = "<ul>";
        errors.forEach(error => {
          errorHTML += `<li>${error}</li>`;
        });
        errorHTML += "</ul>";
      } else if (errors.length === 1) {
        errorHTML = errors[0];
      } else {
        errorHTML = "Unknown error.";
      }

      $(config.container)
        .find(".errors")
        .html(errorHTML)
        .show();
    };

    const hideErrors = () => {
      $(config.container)
        .find(".errors")
        .hide();
    };

    const countdownToRedirect = (redirectUrl, seconds, elem) => {
      // This is a little barbaric, but it is just a method that calls itself again and again.
      elem.removeClass("hidden");
      elem.find("#timer").text(seconds);
      if (seconds) {
        window.setTimeout(
          countdownToRedirect.bind(null, redirectUrl, --seconds, elem),
          1000
        );
      } else {
        window.location.replace(redirectUrl);
      }
    };

    const usernamePreflight = () => {
      let preflightUrl = config.preflightUrl;

      if (typeof preflightUrl !== "undefined") {
        disableNextButton();
        hideErrors();

        fetch(preflightUrl, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            username: $(config.container)
              .find('input[name="username"]')
              .val(),
            CRAFT_CSRF_TOKEN: $(config.container)
              .find('input[name="CRAFT_CSRF_TOKEN"]')
              .val()
          })
        })
          .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              console.log(response.status);
              showErrors([
                "There is a problem logging you in. Please contact Gemba with your issue."
              ]);
            }
          })
          .then(data => {
            if (typeof data !== "undefined") {
              if (data.success) {
                config.auth_service =
                  data.action === "lms" ? LEARNING_SYSTEM_FLAG : AUTH0_FLAG;

                // TODO, if we can log into LMS here, then we don't need to redirect
                if (data.action === "lms" && data.redirect) {
                  countdownToRedirect(
                    data.redirect,
                    3,
                    $("#redirect-countdown")
                  );
                  hideUsernameFields();
                } else {
                  showPasswordFields();
                  hideUsernameFields();
                }
              } else {
                showErrors(data.errors);
              }
            }
          })
          .catch(error => {
            console.log(error);
            showErrors([
              "There is a problem logging you in. Please contact Gemba with your issue."
            ]);
          })
          .finally(() => {
            enableNextButton();
          });
      } else {
        // This should not happen, but add it just to give the user some feedback.
        showErrors([
          "There is a problem logging you in. Please contact Gemba with your issue."
        ]);
        enableNextButton();
      }
    };

    const auth0Login = () => {
      let webAuth = new WebAuth({
        domain: config.domain,
        clientID: config.clientId,
        redirectUri: config.redirectUri,
        scope: "openid email profile",
        responseMode: "form_post",
        responseType: "id_token"
      });

      let submitBtn = $(this).find('[type="submit"]');
      submitBtn.prop("disabled", true);

      const username = $(config.container)
        .find('input[name="username"]')
        .val();
      const password = $(config.container)
        .find('input[name="password"]')
        .val();

      // username should already be filled from the preflight
      if (username === "" || password === "") {
        submitBtn.prop("disabled", false);
        showErrors(["Please enter your password"]);

        return;
      }

      /**
       * Initializes the Auth0 login Lock https://github.com/auth0/lock
       */
      webAuth.redirect.loginWithCredentials(
        {
          username,
          email: username,
          password,
          realm: config.connection
        },
        (err, authResult) => {
          submitBtn.prop("disabled", false);

          console.log(err);
          if (err) {
            let errorMsg = "";
            switch (err.error) {
              case "access_denied":
                errorMsg = "Your username or password is invalid.";
                break;
              default:
                errorMsg = err.error_description;
            }
            showErrors([errorMsg]);
            //clear password field
            $(config.container)
              .find('input[name="password"]')
              .val("");
          }

          // TODO: handle the token
          //console.log(authResult);
        }
      );
    };

    const learningSystemLogin = () => {
      // TODO
    };

    $(config.container)
      .find(".action-next")
      .on("click", e => {
        usernamePreflight();
      });

    $(config.container)
      .find(".action-back")
      .on("click", e => {
        showUsernameFields();
        hidePasswordFields();
      });

    $(config.container).on("submit", function submitLogin(e) {
      e.preventDefault();

      if (config.auth_service === LEARNING_SYSTEM_FLAG) {
        learningSystemLogin();
      } else {
        auth0Login();
      }
    });

    const init = () => {
      console.log("loaded");

      // This isn't really needed, but declaring it here
      // just so the `auth_service` attribute exists when needed.
      config.auth_service = config.auth_service || AUTH0_FLAG;

      // Make sure everything is visible.
      showUsernameFields();
      hidePasswordFields();
    };

    init();
  };

  window.gembaAuth0 = window.gembaAuth0 || {};

  window.gembaAuth0.verifyDomain = function(config) {
    let webAuth = new WebAuth({
      domain: config.domain,
      clientID: config.clientId,
      redirectUri: config.redirectUri
    });
    webAuth.crossOriginVerification();
  };

  window.gembaAuth0.initLoginForm = initLoginForm;
})(jQuery, window, document);
